import * as React from 'react';

import SimpleAlert from '@myaccount/alert/Simple';
import TransitionAlert from '@myaccount/alert/Transition';

const AlertComponent = (props) => {

    const type = props?.type ?? false;

    switch (type) {
        case 'alert':
            return <SimpleAlert
                        {...props}
                    />
        default:
            return <TransitionAlert
                        {...props}
                    />
    }
}

export default AlertComponent
