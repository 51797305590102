import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import defaults from './defaults';

const TransitionAlert = withSubtheme((props) => {
    const {
        className,
        classes,
        alert,
        message,
        severity
    } = props;

    const [open, setOpen] = React.useState(true);

    const messageData = message ?? false;
    const severityData = severity ?? 'error';

    return(
        <>
        {messageData && <div className={classes ? classes : className}>
            <Collapse in={open}>
                <Alert
                    severity={severityData}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    {...alert}
                >
                    <div dangerouslySetInnerHTML={{ __html: messageData }} />
                </Alert>
            </Collapse>
        </div>
        }
        </>
    )
}, 'alertComponent', defaults)

export default TransitionAlert
